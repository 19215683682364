export interface UserState {
    id?: string,
    email?: string,
    createdAt?: string,
    profileId?: string,
    firstName?: string,
    lastName?: string,
    postcode?: string,
    street?: string,
    city?: string,
    birthDate?: string
}

export const UPDATE_USER = 'UPDATE_USER'

interface UpdateUserAction {
    type: typeof UPDATE_USER
    user: object
}

export const DELETE_USER = 'DELETE_USER'

interface DeleteUserAction {
    type: typeof DELETE_USER
}

export type UserActionTypes = UpdateUserAction | DeleteUserAction


export interface InterfaceState {
    flashMessage: any
    navi: {
        name?: string | null
    }
    currentView: {
        name?: string | null
    }
}

export const UPDATE_FLASH_MESSAGE = 'UPDATE_FLASH_MESSAGE'

interface UpdateFlashMessageAction {
    type: typeof UPDATE_FLASH_MESSAGE
    payload: object
}

export const RESET_FLASH_MESSAGE = 'RESET_FLASH_MESSAGE'

interface ResetFlashMessageAction {
    type: typeof RESET_FLASH_MESSAGE
}

export const UPDATE_NAVI = 'UPDATE_NAVI'

interface UpdateNaviAction {
    type: typeof UPDATE_NAVI
    view: object
}

export const UPDATE_CURRENT_VIEW = 'UPDATE_CURRENT_VIEW'

interface UpdateCurrentViewAction {
    type: typeof UPDATE_CURRENT_VIEW
    view: object
}

export type InterfaceActionTypes = UpdateFlashMessageAction | ResetFlashMessageAction | UpdateNaviAction | UpdateCurrentViewAction 