import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './containers/App'
import * as serviceWorker from './serviceWorker'

import { store } from './store'
import { Provider } from 'react-redux'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-boost'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
// import introspectionQueryResultData from './fragmentTypes.json'

import ROUTES from './routes'
import Auth from './utils/Auth'

import './assets/styles/styles.css'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

// Sentry bug tracking
Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN})

// Apollo
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API
})

const authLink = setContext((_, { headers }) => {
    const token = Auth.getToken()
    return {
        headers: {
            ...headers,
            Application: 'account-management',
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error: any) => {
            if (['WymagaZalogowania', 'NiepoprawnyToken', 'NiepoprawnyToken'].includes(error.message)) {
                window.location.href = ROUTES.home.path
            }
        })
    }

    if (networkError) console.log('Apollo error')
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
    // introspectionQueryResultData
})

const client = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        errorLink,
        httpLink
    ]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all'
        }
    },
    cache: new InMemoryCache({ fragmentMatcher })
})

const theme = createMuiTheme({
   palette: {
    primary: {
      light: '#be9c91',
      main: '#8d6e63',
      dark: '#5f4339'
    },
    secondary: {
      light: '#cfcfcf',
      main: '#9e9e9e',
      dark: '#707070'
    },
  }
});

// DOM render
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <ApolloProvider client={client}>
                <MuiThemeProvider theme = { theme }>
                    <App />
                </MuiThemeProvider>
            </ApolloProvider>
        </Router>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
