import { DictionariesState, DictionariesActionTypes, UPDATE_DICTIONARIES} from './types'

const initialState: DictionariesState = []

export function dictionariesReducer(
  state = initialState,
  action: DictionariesActionTypes
): DictionariesState {
  switch (action.type) {
    case UPDATE_DICTIONARIES: {
      state = {...action.dictionaries}
      
      return {
        ...state
      }
    }
    default:
      return state
  }
}