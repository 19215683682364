
import PasswordReset from '../containers/PasswordReset'
import PasswordResetRequest from '../containers/PasswordReset/PasswordResetRequest'
import UserActivate from '../containers/UserActivate'
import Home from '../containers/Home';

const ROUTES = {
  home: {
    path: "/",
    component: Home
  },
  passwordReset: {
    path: "/reset-password/:token",
    component: PasswordReset
  },
  passwordResetRequest: {
    path: "/reset-password",
    component: PasswordResetRequest
  },
  userActivate: {
    path: "/activate/:token",
    component: UserActivate
  }
};

export default ROUTES