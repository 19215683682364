import { createStyles, Theme } from '@material-ui/core/styles'

const uiStyles = (theme: Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    position: 'relative',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paper_auth: {
    marginTop: '18vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alignRight: {
    textAlign: 'right'
  },
  table: {
    width: '100%'
  },
  box: {
    position: 'relative',
    marginTop: theme.spacing(4),
  },
  btn_add_value: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  btn_back: {
    minWidth: theme.spacing(4),
    maxWidth: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  fab_edit: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  bottomSpace: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  leftSpace: {
    marginLeft: theme.spacing(4)
  },
  fieldDate: {
    marginTop: theme.spacing(1),
    minWidth: '30%'
  },
  lockIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[500]
  },
  divider: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  chip: {
    marginLeft: theme.spacing(2)
  },
  formControl: {
    minWidth: '100%',
  },
  cell: {
    padding: theme.spacing(0.5)
  },
  cellHead: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontSize: '0.75em',
    lineHeight: 1.4
  },
  textField: {
    width: '100%'
  },
  inputLabel: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  typography: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  menuTabs: {
    marginTop: theme.spacing(3),
  },
  tab: {
    display: 'none',
  },
  tabShow: {
    display: 'block',
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxHeight: 70,
    maxWidth: '100%'
  },
  imgDel: {
    margin: '2px auto',
    zIndex: 100,
    width: 30,
    height: 30
  },
  alignCenter: {
    textAlign: 'center'
  },
  chipWithMargin: {
    margin: theme.spacing(1, 1, 1, 0)
  },
})

export default uiStyles