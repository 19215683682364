import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { dictionariesReducer } from './dictionaries/reducers'
import { interfaceReducer, userReducer } from './interface/reducers'

const rootReducer = combineReducers({
    user: userReducer,
    dictionaries: dictionariesReducer,
    interface: interfaceReducer
})

export type AppState = ReturnType<typeof rootReducer>

export const store = createStore(rootReducer, composeWithDevTools(
    // applyMiddleware(...middlewares),
    // other store enhancers if any
))