import React, { useRef } from 'react'
import Auth from '../../utils/Auth'
import { RouteComponentProps, Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../routes'

// UI
import uiStyles from '../../assets/styles/styles'
import {
  Avatar,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
  WithStyles
} from '@material-ui/core'
import { withStyles, } from '@material-ui/core/styles'
import { LockOutlined } from '@material-ui/icons'

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
}

type State = {
}

class Home extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
    }

    Auth.clear()
  }

  render() {
    const { classes } = this.props

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper_auth}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Kellner: Obsługa konta
          </Typography>
          <br />
          {<Grid container justify="flex-end">
            <Grid xs={12} item component="div" className={classes.alignCenter}>
              <Link variant="body2" component={LinkRouter} to={ROUTES.passwordResetRequest.path}>
                Zresetuj swoje hasło
             </Link>
            </Grid>
          </Grid>}
        </div>
      </Container>
    )
  }
}

export default withStyles(uiStyles)(Home)