import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { updateFlashMessage, resetFlashMessage } from '../../store/interface/actions'

// UI
import uiStyles from './uiStyles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
    Snackbar,
    Button,
    IconButton,
    SnackbarContent
} from '@material-ui/core'
import { Close, Error } from '@material-ui/icons'
import clsx from 'clsx'

interface FMProps extends WithStyles<typeof uiStyles> {
    flashMessage?: any
    resetFlashMessage: typeof resetFlashMessage
}

interface FMState { }

class FlashMessage extends React.Component<FMProps, FMState> {
    handleClose = (e: React.SyntheticEvent | React.MouseEvent, reason?: string): void => {
        if (reason === 'clickaway') return
        this.props.resetFlashMessage()
    }

    render() {
        const { classes } = this.props
        const { open, type, message, autoHideDuration, refreshAction, closeAction } = this.props.flashMessage

        let actions: Array<any> = []

        if (refreshAction) {
            actions.push(<Button key="undo" color="inherit" size="small" onClick={() => { window.location.reload() }}>
                Odśwież dane
      </Button>)
        }

        if (closeAction) {
            actions.push(<IconButton key="close" aria-label="Close" color="inherit" onClick={(e) => this.handleClose(e)}>
                <Close className={classes.icon} />
            </IconButton>)
        }

        return (
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={(e) => this.handleClose(e)}
                >
                    <SnackbarContent
                        className={type === 'error' ? classes.error : classes.snackbarContent}
                        aria-describedby="flash-message"
                        message={
                            <span id="flash-message" className={classes.message}>
                                {type === 'error' && <Error className={clsx(classes.icon, classes.iconVariant)} />}
                                {message}
                            </span>
                        }
                        action={actions}
                    />
                </Snackbar>
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    flashMessage: state.interface.flashMessage,
})

export default connect(mapStateToProps, { resetFlashMessage })(withStyles(uiStyles)(FlashMessage))

/**
 * Flash Message Init
 */

interface FMIProps {
    open?: boolean
    type?: string
    message?: string
    autoHideDuration?: number | null
    refreshAction?: boolean
    closeAction?: boolean
    updateFlashMessage: typeof updateFlashMessage
}

const FlashMessageUpdater: React.FC<FMIProps> = (props) => {
    props.updateFlashMessage(props)

    return null
}

const FlashMessageInit = connect(null, { updateFlashMessage })(FlashMessageUpdater)

/**
 * Success Alert
 */

export const SuccessAlert: React.FC<any> = (props) => (
    <FlashMessageInit
        type="success"
        open={true}
        autoHideDuration={props.autoHideDuration || 4000}
        message={props.message || 'Zapisano pomyślnie.'}
        {...props}
    />
)

/**
 * Error Alert
 */

export const ErrorAlert: React.FC<any> = (props) => {
    let message: string = 'Wystąpił błąd. Spróbuj ponownie.'
    let refreshAction: boolean = false

    // Graphql error
    if (props.error) {
        if (props.error.graphQLErrors) {
            props.error.graphQLErrors.forEach((error: any) => {
                message = error.details || ''

                if (error.message === 'NiezgodnaWersja') refreshAction = true
                if (message.length === 0) message = error.message
            })
        }

        if (props.error.networkError) message = 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.'
    }

    // Field error
    if (props.fieldError) {
        if (props.modelName.length === 0) {
            console.log('Brak podanego modelu do mapowania fieldError.')
            return null
        }

        if (props.fieldError.length === 0) return null

        if (props.fieldError[0].field) {
            message = `Błąd: ${fieldName(props.modelName, props.fieldError[0].field)} - ${props.fieldError[0].messages[0]}`
        } else message = `Błędy w formularzu - ${props.fieldError[0].messages[0]}`

        // props.fieldError.forEach((error: any) => {
        //   message = `${message}<br />${error.field} ${error.messages[0]}`
        // })
    }

    return (
        <FlashMessageInit
            type="error"
            open={true}
            message={props.message || message}
            refreshAction={props.refreshAction || refreshAction}
            closeAction={true}
            {...props}
        />
    )
}

const fieldName = (model: string, field: string): string => {
    const names: Object = {
        document: {
            organizationalUnitId: 'Właściciel dokumentu',
            type: 'Rodzaj dokumentu',
            number: 'Numer dokumentu',
            year: 'Rok budżetowy',
            resolutionDate: 'Data podjęcia dokumentu',
            lifeEntryDate: 'Data wejścia w życie'
        },
        dictionaryValue: {
            value: 'Wartość',
            symbol: 'Symbol',
            name: 'Nazwa',
            description: 'Objaśnienie',
            sectionId: 'Dział',
            overwriteData: 'Nowa wersja',
            active_from: 'Data rozpoczęcia funkcjonowania',
            activeTo: 'Data zamknięcia',
            paragraphId: 'Paragraf'

        }
    }

    return names[model] && names[model][field] ? names[model][field] : field
}