import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ROUTES from '../../routes'
import Auth from '../../utils/Auth'

// UI
import uiStyles from '../../assets/styles/styles'
import {
  CssBaseline,
  Avatar,
  Typography,
  Container,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { ErrorAlert } from '../../components/FlashMessage'

// GraphQL
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from '../../components/Loading'

export const USER_ACTIVATE_MUTATION = gql`
  mutation($token: String!){
    userActivate(input: { token: $token }){
      errors {
        field
        messages
      }
    }
  }
`

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

class userActivate extends React.Component<Props, {}> {
  formRef: any;

  constructor(props: Props) {
    super(props)

    Auth.clear()
  }

  componentDidMount() {
    this.formRef.submit();
  }

  render() {
    const { classes } = this.props
    const { token } = this.props.match.params

    return (
      <Mutation
        mutation={USER_ACTIVATE_MUTATION}
        variables={{ token }}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            {loading && <Loading />}

            {!loading &&
              <div className={classes.paper_auth}>
                <Avatar className={classes.avatar}>
                  <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Aktywacja konta
              </Typography>
                <br />
                <Typography component="p" variant="body2">
                  {(!loading && data) && `Konto zostało aktywowane. Możesz się teraz zalogować w aplikacji.`}
                  {(!loading && error) && `Aktywacja konta nie powiodła się.`}
                </Typography>
                <ValidatorForm
                  ref={(ref: any) => this.formRef = ref}
                  onSubmit={submitForm}
                  className={classes.form}
                >
                  {(data && data.userActivate.errors) && <ErrorAlert modelName="user" fieldError={data.userActivate.errors} />}
                </ValidatorForm>
              </div>}
          </Container>
        )}
      </Mutation>
    )
  }
}

export default withStyles(uiStyles)(userActivate)