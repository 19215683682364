import React from 'react'

// UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    Button,
    CircularProgress
} from '@material-ui/core'
import { Link as LinkRouter } from 'react-router-dom'

const uiStyles = makeStyles((theme: Theme) =>
    createStyles({
        backBtn: {
            margin: theme.spacing(1)
        },
        submitBtn: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        longBtn: {
            margin: theme.spacing(3, 0, 2),
        },
    })
)

type Props = {
    to?: string
    text?: string
    disabled?: boolean
}

/**
 * Back
 */
export const BackActionBtn: React.FC<Props> = (props) => {
    const classes = uiStyles()

    return (
        <Button component={LinkRouter} to={props.to || '/'} size="small" className={classes.backBtn}>
            {props.text || 'Anuluj'}
        </Button>
    )
}

/**
 * Submit
 */
export const SubmitActionBtn: React.FC<Props> = (props) => {
    const classes = uiStyles()

    return (
        <Button type="submit" size="medium" variant="contained" color="primary" className={classes.submitBtn}>
            {props.text || 'Zapisz'}
        </Button>
    )
}

/**
 * Loader
 */
export const LoaderBtn = () => (
    <Button
        type="button"
        variant="contained"
        disabled
    >
        <CircularProgress size={24} color="primary" />
    </Button>
)

/**
 * Long Button
 */
export const LongActionBtn: React.FC<Props> = (props) => {
    const classes = uiStyles()

    return (
        <Button fullWidth type="submit" size="large" variant="contained" color="primary" className={classes.longBtn} disabled={props.disabled}>
            {props.text || 'Zapisz'}
        </Button>
    )
}

/**
 * Long button Loader
 */
export const LoaderLongBtn = () => {
    const classes = uiStyles()

    return (
        <Button
            fullWidth
            type="button"
            size="large"
            variant="contained"
            className={classes.longBtn}
            disabled
        >
            <CircularProgress size={24} color="primary" />
        </Button>
    )
}


/**
 * Long medium Button
 */
export const LongMdActionBtn: React.FC<Props> = (props) => {
    const classes = uiStyles()

    return (
        <Button fullWidth type="submit" size="medium" variant="contained" color="secondary" className={classes.longBtn}>
            {props.text || 'Zapisz'}
        </Button>
    )
}

/**
 * Long medium button Loader
 */
export const LoaderMdLongBtn = () => {
    const classes = uiStyles()

    return (
        <Button
            fullWidth
            type="button"
            size="medium"
            variant="contained"
            className={classes.longBtn}
            disabled
        >
            <CircularProgress size={24} color="primary" />
        </Button>
    )
}
