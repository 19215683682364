import React from "react";
import { RouteComponentProps, Link as LinkRouter } from "react-router-dom";
import ROUTES from "../../routes";

// UI
import uiStyles from "../../assets/styles/styles";
import {
  CssBaseline,
  Avatar,
  Link,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff, LockOutlined } from "@material-ui/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ErrorAlert, SuccessAlert } from "../../components/FlashMessage";
import { LongActionBtn, LoaderLongBtn } from "../../components/FormActionLinks";
import clsx from "clsx";

// GraphQL
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

export const RESET_PASSWORD_MUTATION = gql`
  mutation ($newPassword: String!, $token: String!) {
    resetPasswordUpdate(input: { newPassword: $newPassword, token: $token }) {
      errors {
        field
        messages
      }
    }
  }
`;

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any;
}

type State = {
  newPassword: string;
  repeatPassword: string;
  showPassword: boolean;
  showRepeatPassword: boolean;
};

class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newPassword: "",
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.newPassword) {
        return false;
      }

      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowRepeatPassword = () => {
    this.setState({ showRepeatPassword: !this.state.showRepeatPassword });
  };

  render() {
    const { classes } = this.props;
    const { token } = this.props.match.params;
    const { newPassword, repeatPassword, showPassword, showRepeatPassword } =
      this.state;

    return (
      <Mutation
        mutation={RESET_PASSWORD_MUTATION}
        variables={{ newPassword, repeatPassword, token }}
        onCompleted={(data: any) => {
          this._confirm(data);
        }}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper_auth}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Zmiana hasła logowania
              </Typography>
              {!loading && data && !data.resetPasswordUpdate.errors ? (
                <Typography
                  component="p"
                  variant="body2"
                  className={clsx(classes.paper, classes.alignCenter)}
                >
                  {!loading &&
                    data &&
                    !data.resetPasswordUpdate.errors &&
                    `Hasło zostało zmienione. Możesz się teraz zalogować w aplikacji.`}
                </Typography>
              ) : (
                <ValidatorForm onSubmit={submitForm} className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        required
                        fullWidth
                        name="newPassword"
                        label="Nowe hasło"
                        id="newPassword"
                        autoComplete="newPassword"
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={(e: { target: { value: string } }) =>
                          this.setState({ newPassword: e.target.value })
                        }
                        validators={["required"]}
                        errorMessages={["Pole wymagane."]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        required
                        fullWidth
                        variant="outlined"
                        id="field-confirm-new-password"
                        type={showRepeatPassword ? "text" : "password"}
                        label="Potwierdź nowe hasło"
                        value={this.state.repeatPassword}
                        onChange={(e: { target: { value: string } }) =>
                          this.setState({ repeatPassword: e.target.value })
                        }
                        validators={["isPasswordMatch", "required"]}
                        errorMessages={[
                          "Hasła nie zgadzają się.",
                          "Pole wymagane.",
                        ]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowRepeatPassword}
                              >
                                {showRepeatPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {!loading && <LongActionBtn text="Resetuj hasło" />}
                  {loading && <LoaderLongBtn />}
                  {!loading && data && (
                    <SuccessAlert message="Hasło pomyślnie zmienione" />
                  )}
                  {error && <ErrorAlert error={error} />}
                  {data && data.resetPasswordUpdate.errors && (
                    <ErrorAlert
                      modelName="resetPassword"
                      fieldError={data.resetPasswordUpdate.errors}
                    />
                  )}
                </ValidatorForm>
              )}
            </div>
          </Container>
        )}
      </Mutation>
    );
  }

  _confirm = async (data: any) => {
    //if (data)
    //this.props.history.push(ROUTES.login.path)
  };
}

export default withStyles(uiStyles)(ResetPassword);
