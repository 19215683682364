import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import { updateUser } from '../../store/interface/actions'

import ROUTES from '../../routes'
import FlashMessage from '../../components/FlashMessage'


type AProps = {
  updateUser: typeof updateUser
}

class App extends React.Component<AProps> {
  render() {
    return (
      <>
        <Switch>
          <Route exact path={ROUTES.home.path} component={ROUTES.home.component} />
          
          <Route exact path={ROUTES.passwordReset.path} component={ROUTES.passwordReset.component} />
          <Route exact path={ROUTES.passwordResetRequest.path} component={ROUTES.passwordResetRequest.component} />

          <Route exact path={ROUTES.userActivate.path} component={ROUTES.userActivate.component} />
        </Switch>

        <FlashMessage />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { updateUser })(App)
